import {
  isMobile,
  osVersion,
  osName,
  mobileModel,
  mobileVendor,
} from 'react-device-detect';
import config from '@airasia-common/libraries/config';

export const deviceAgentDetect = { isMobile, userAgent: navigator.userAgent };

export const deviceInfo = {
  os_version: osVersion,
  os: osName,
  ga_client_id: config.REACT_APP_GA_CLIENT_ID,
  device_model: mobileModel,
  device_brand: mobileVendor,
};
