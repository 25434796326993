import { createStore, applyMiddleware, compose } from 'redux';
import { createMiddleware } from 'redux-beacon';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import logger from '@redux-beacon/logger';
import createRootSaga from '@airasia-hotels/core/sagas';
import rootReducer from '@airasia-hotels/core/rootReducer';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { eventsMap, gtm } from './App.analytics';

const persistConfig = {
  key: 'airasia-hotels-web',
  storage,
  whitelist: ['user', 'metadata'],
  // Add any other configurations such as blacklist or whitelist as needed
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware();
const getComposer = () => {
  const reduxDevToolsCompose =
    (process.env.NODE_ENV === 'development' &&
      process.browser &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || // eslint-disable-line no-underscore-dangle
    compose;
  return reduxDevToolsCompose;
};

const middleware = [sagaMiddleware];
if (gtm) {
  const options = process.env.NODE_ENV === 'development' ? { logger } : {};
  middleware.push(createMiddleware(eventsMap, gtm, options));
}

if (process.env.NODE_ENV === 'development') {
  middleware.push(createLogger({ collapsed: true }));
}

const rootSaga = createRootSaga();
const middlewareEnhancer = applyMiddleware(...middleware);
const composer = getComposer();
const enhancer = composer(middlewareEnhancer);

// Create the store with the persisted reducer
const store = createStore(persistedReducer, {}, enhancer);
const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);

export { store, persistor };
