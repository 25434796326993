import React, { Suspense } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'; // Import PersistGate
import theme from '@airasia-common/libraries/theming/theme';
import { setCurrentDeviceAgent } from '@airasia-common/libraries/utilities/DeviceAgentHelper';
import Row from '@airasia-common/react-dom/components/Container/Row';
import { HeadHelmet } from '@airasia-common/react-dom';
import ActivityIndicator from '@airasia-common/react-dom/components/ActivityIndicator';
import Column from '@airasia-common/react-dom/components/Container/Column';
import loadable from 'loadable-components';
import { deviceAgentDetect } from './utils/DeviceAgent';
import { constants, useWindowSize } from './utils';
import { store, persistor } from './App.store'; // Ensure persistor is also imported
import Styled from './utils/Styled';
import {
  DEFAULT_META_DESC,
  DEFAULT_META_KEYWORDS,
  DEFAULT_TITLE,
  ROBOTS_NO,
} from './utils/constants';

const Home = loadable(() =>
  import(/* webpackChunkName:"Home" */ './views/Home')
);
const Hotel = loadable(() =>
  import(/* webpackChunkName:"Hotel" */ './views/Hotel')
);

const Main = Styled(Column, {
  position: 'relative',
  minHeight: '100%',
  height: '100%',
});

function App() {
  // Set device id and user agent
  setCurrentDeviceAgent(deviceAgentDetect);

  const loadingComponents = (
    <Row margin={64} justifyContent="center">
      <ActivityIndicator />
    </Row>
  );
  const { isMobileView } = useWindowSize();

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={loadingComponents} persistor={persistor}>
          <Router>
            <Main>
              <HeadHelmet
                metaDescription={DEFAULT_META_DESC}
                metaKeywords={DEFAULT_META_KEYWORDS}
                cannonicalUrl={
                  'https://www.airasia.com' + window.location.pathname
                }
                metaRobots={ROBOTS_NO}
              />
              <Suspense fallback={loadingComponents}>
                <Switch>
                  <Route path={constants.ROUTE.HOTEL.ROOT}>
                    <Hotel isMobileView={isMobileView} />
                  </Route>
                  <Route path={constants.ROUTE.ROOT}>
                    <Home />
                  </Route>
                </Switch>
              </Suspense>
            </Main>
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
