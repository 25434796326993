import GoogleTagManager from '@redux-beacon/google-tag-manager';
import { analyticsEvents } from '@airasia-hotels/core';

const gtm = GoogleTagManager({ dataLayerName: 'gtmDataLayer' });

// shared analytics events
const commonEventsMap = {
  ...analyticsEvents.filter,
  ...analyticsEvents.common,
};

// Hotels analytics events
const hotelEventsMap = {
  ...analyticsEvents.hotel,
};

const bookingEventsMap = {
  ...analyticsEvents.booking,
};

const eventsMap = {
  ...commonEventsMap,
  ...hotelEventsMap,
  ...bookingEventsMap,
  ...analyticsEvents.map,
};

export { eventsMap, gtm };
