/**
 * Calculates how many seconds from now you should call refreshToken.
 *
 * For example: If your token expires in 12 minutes, and you pass `thresholdMinutes = 5`,
 * this will return 420, which means "call refresh in 420 seconds" (7 minutes).
 * If it's already less than 5 minutes remaining, it returns 0 (call immediately).
 *
 * @param {string} token - The JWT token.
 * @param {number} thresholdMinutes - How many minutes before actual expiration you want to refresh.
 * @returns {number} - The number of seconds from now until you should refresh (or 0 if it's already under threshold).
 */
function getSecondsUntilRefresh(token, thresholdMinutes = 5) {
  if (!token) return 0;

  try {
    const base64Url = token.split('.')[1];
    if (!base64Url) {
      throw new Error('Invalid token format');
    }

    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(c => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );

    const { exp } = JSON.parse(jsonPayload);
    if (!exp) return 0;

    const nowInSeconds = Math.floor(Date.now() / 1000);
    const timeToExpireInSeconds = exp - nowInSeconds;

    const thresholdInSeconds = thresholdMinutes * 60;
    const refreshInSeconds = timeToExpireInSeconds - thresholdInSeconds;

    return refreshInSeconds > 0 ? refreshInSeconds : 0;
  } catch (error) {
    console.error('Failed to parse token:', error);
    return 0;
  }
}

export default getSecondsUntilRefresh;
