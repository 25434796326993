import config from '@airasia-common/libraries/config';
export const DEEPLINK_URL = 'airasia://snapandhotel';
export const SNAP = 'snap';
export const HOTEL = 'hotel';
export const SUBSCRIPTION = 'subscription';
export const IGNORE_CACHE = 'ignoreCache=1';

const SNAP_ROUTE = {
  ROOT: `/${SNAP}`,
  FLIGHT_HOTEL_LIST: `/${SNAP}/hotels/list`,
  FLIGHT_LIST: `/${SNAP}/flights/list`,
  TRIP_DETAIL: `/${SNAP}/trip`,
  GUEST_DETAIL: `/${SNAP}/guest`,
  HOTEL_DETAIL: `/${SNAP}/hotel/:hotelId`,
  CHECKOUT: `/${SNAP}/checkout`,
};

const HOTEL_ROUTE = {
  SEARCH_WIDGET: '/hotels',
  ROOT: `/${HOTEL}`,
  HOTEL_LIST: `/${HOTEL}/search`,
  HOTEL_LIST_INDEX: `/${HOTEL}/search/index.html`,
  HOTEL_DETAIL: `/${HOTEL}/:destination/:hotelName/:hotelId`,
  HOTEL_DETAIL_OLD: `/${HOTEL}/:hotelId`,
  TRIP_DETAIL: `/${HOTEL}/:hotelId/:roomId/:rateId/:bedGroupId/:rateOptionId`,
  CONFIRMATION: `/${HOTEL}/confirmation`,
  BOOKING_DETAIL: `/${HOTEL}/booking-detail`,
  MAP_VIEW: `/${HOTEL}/maps`,
  ERROR: `/${HOTEL}/error`,
};

export const ROUTE = {
  ROOT: '/',
  MANAGE_BOOKING: '/manage-booking',
  CHECK_IN: '/check-in',
  SUPPORT: '/support',
  HOTEL: HOTEL_ROUTE,
  SNAP: SNAP_ROUTE,
};

export const WITHOUT_FOOTER = [HOTEL_ROUTE.MAP_VIEW];

export const Variant = {
  Error: 'error',
  Default: 'default',
  Info: 'info',
  Success: 'success',
  Hint: 'hint',
};

export const COUNTRY_CODE_DEFAULT = 'MY';
export const LANGUAGE_DEFAULT = 'en-gb';

export const GuestType = {
  Adult: 'adults',
  Children: 'children',
  Infants: 'infants',
};

export const EMPTY_STATE = {
  EMPTY_HOTEL_LIST: 'empty_hotel_list',
  EMPTY_FLIGHT_HOTEL_SNAP: 'empty_flight_hotel_snap',
  EMPTY_HOTEL_LIST_ON_LOCATION_FILTER: 'empty_hotel_list_on_location_filter',
  EMPTY_HOTEL_LIST_ON_FILTER: 'empty_hotel_list_on_filter',
};

export const LOAD_MORE_THRESHOLD = 5;

export const DEFAULT_LOCALE = 'defaultLocale';
export const UHF_LOCALE_NAME = 'uhfLocale';
export const UHF_CURRENCY_NAME = 'uhfCurrency';

export const TRIP_INFO_KEY = 'tripInfo';
export const COOKIE_GA_ID = '__airasiaga';
export const COOKIE_CLEVER_TAP_ID = 'WZRK_G';
export const COOKIE_SNOW_PLOW_ID = 'airasia.comid.5f5c';
export const COOKIE_FILTER_LOCATIONINFO = 'cookie_filter_location_info';

export const PAY_AT_HOTEL_STATUS_BOOKING_CONFIRMATION_SCOPED_TRANSLATE = [
  'payment_failed',
  'user_refund_failed',
];

export const PAY_AT_HOTEL_STATUS_BOOKING_DETAIL_SCOPED_TRANSLATE = [
  'payment_failed',
  'user_cancelled',
  'hotel_cancelled',
  'airasia_cancelled',
  'user_refund_pending',
  'user_refund_failed',
  'user_inventory_release_pending',
  'booking_failed',
  'user_inventory_release_failed',
  'aa_inventory_release_failed',
];

export const TRIP_DETAIL_PAYMENT_OPTIONS = {
  PAY_LATER: 1,
  PAY_NOW: 2,
};

export const PAY_LATER_STATUS_DISPLAY_PAY_NOTHING_DATE = {
  BOOKING_CONFIRMATION: [
    'payment_pending',
    'inventory_pending',
    'refund_pending',
    'provisionally_confirmed',
    'confirmed',
    'completed',
  ],
  BOOKING_DETAIL: [
    'payment_pending',
    'inventory_pending',
    'refund_pending',
    'provisionally_confirmed',
    'confirmed',
    'completed',
  ],
};

export const PAY_LATER_FORMAT_TIME = 'MMMM dd, yyyy';

export const PAY_LATER_FORMAT_SHORT_TIME = 'MMM dd, yyyy';

export const DISCOUNT_MODAL_SHOW_ON = {
  LISTING: 'LISTING',
  DETAILS: 'DETAILS',
  GUEST_DETAILS: 'GUESTDETAILS',
  LISTING_DETAILS: 'LISTING/DETAILS',
  DETAILS_GUEST_DETAILS: 'DETAILS/GUESTDETAILS',
  ALL: 'LISTING/DETAILS/GUESTDETAILS',
};

export const DISCOUNT_MODAL_SHOW_FOR = {
  LOGGED_IN: 'LOGGED_IN',
  NON_LOGGED_IN: 'NON_LOGGED_IN',
  ALL: 'LOGGED_IN/NON_LOGGED_IN',
};

export const SESSION_STORAGE_DISCOUNT_MODAL_OPENED = 'aa_discount_modal_opened';

export const DECIMAL_TYPE = {
  FLOAT: 'FLOAT',
  ROUND: 'ROUND',
  FLOOR: 'FLOOR',
  CEIL: 'CEIL',
};

export const MAP_IMAGE_URL =
  'https://static.airasia.com/snap/images/Map_View_Button_Image.png';

export const MOVE_MAP_IMAGE_URL =
  config.REACT_APP_ENVIRONMENT === 'PRODUCTION'
    ? 'https://static.airasia.com/snap/images/Map_View_Button_Image_MOVE.png'
    : 'https://static.stgairasia.com/snap/images/Map_View_Button_Image_MOVE.png';

export const HOTELS_CHILLI_CHANNEL = {
  GroupID: '5e9e5b0e23bd8d101f738958',
  HOTELS_HOTEL_LISTING: {
    type: 'HOTELS-HOTEL-LISTING',
    id: '61efc13f034a6a244cf67ce9',
  },
  HOTELS_ROOM_SELECT: {
    type: 'HOTELS-ROOM-SELECT',
    id: '61f222b0b3ec87c89b1b2f28',
  },
  HOTELS_GUEST_DETAILS: {
    type: 'HOTELS-GUEST-DETAILS',
    id: '61f222bab3ec87c89b1b2f29',
  },
  HOTELS_MAPS: {
    type: 'HOTELS-MAPS',
    id: '61f222c1b3ec87c89b1b2f2a',
  },
};

export const HOTELS_FEEDBACK_PATH = {
  HOTELS_HOTEL_LISTING:
    '/snap/feedback/hotel-web-hotel-listing/{{locale}}/file.json',
  HOTELS_ROOM_SELECT:
    '/snap/feedback/hotel-web-room-select/{{locale}}/file.json',
  HOTELS_GUEST_DETAILS:
    '/snap/feedback/hotel-web-guest-details/{{locale}}/file.json',
  HOTELS_MAPS: '/snap/feedback/hotel-web-maps/{{locale}}/file.json',
};

export const MALAYSIA_COUNTRY_CODE = 'MY';

export const FILTER_TYPE = {
  SORT_BY: 'SORT_BY',
  GUEST_RATING: 'GUEST_RATING',
  AMENITIES: 'AMENITIES',
  HOTEL_CLASS: 'HOTEL_CLASS',
  PRICE_FILTER: 'PRICE_FILTER',
  PAYMENT_TYPE: 'PAYMENT_TYPE',
  PROMOTIONS: 'PROMOTIONS',
  NEIGHBORHOOD: 'NEIGHBORHOOD',
  PROPERTY_TYPE: 'PROPERTY_TYPE',
  LANDMARK: 'LANDMARK',
};

export const ROBOTS_NO = 'nofollow, noindex';
export const AUTO_HIDE_TIMER = 10000;
export const ROBOTS_YES = 'follow, index';
export const DEFAULT_META_DESC =
  'Book the best hotels online with AirAsia Hotels and enjoy exclusive promos & deals for over 500k+ hotels today! Great Prices ✓ Easy Booking ✓ Safe & Secure ✓';
export const DEFAULT_META_KEYWORDS = 'Hotels booking';
export const DEFAULT_TITLE =
  'Discover and Book Your Ideal Hotel Online | AirAsia Hotels';

export const CITY = 'city';
export const ADSID = 'hotels_bookingconfirmation_native';
export const SLOT =
  '/10025506/AirAsia.com/hotels/hotels_bookingconfirmation_native';
export const WIDTH = 'fluid';

export const ENV_TYPE =
  config.REACT_APP_ENVIRONMENT === 'PRODUCTION'
    ? 'https://static.airasia.com'
    : 'https://static.stgairasia.com';

export const SOLD_OUT = 'sold_out';
